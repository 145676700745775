import axios from 'axios';
import { API_LINK } from './constants/config';

const instance = axios.create({
  baseURL: API_LINK,
  headers: {
    'Content-Type': 'application/json',
  },
});

instance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
  'token'
)}`;

export default instance;
