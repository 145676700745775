import {
  createStore,
  Reducer,
  combineReducers,
  applyMiddleware,
  Store,
  compose,
} from 'redux';
import thunk from 'redux-thunk';
import { MODE } from '../constants/config';
import { authReducer, AuthState } from './auth/auth.reducer';
import { orgsReducer, OrgsState } from './orgs/orgs.reducer';

export type RootState = {
  auth: AuthState;
  orgs: OrgsState;
};

const rootReducer: Reducer<RootState> = combineReducers({
  auth: authReducer,
  orgs: orgsReducer,
});

const getStore = () => {
  let store = createStore(rootReducer, applyMiddleware(thunk));
  if (MODE === 'local') {
    store = createStore(
      rootReducer,
      compose(
        applyMiddleware(thunk),
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ && // eslint-disable-line
          (window as any).__REDUX_DEVTOOLS_EXTENSION__() // eslint-disable-line
      )
    );
  }
  return store;
};

export const store: Store<RootState> = getStore();
