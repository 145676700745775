import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components';
import notFound from './not-found.svg';

export const NotFound: React.FC = (): JSX.Element => {
  const history = useHistory();
  const redirectToHomePage = () => {
    history.push('/orgs');
  };
  return (
    <div className="not-found">
      <div className="not-found__text">
        <h1>Something&apos;s gone wrong</h1>
        <p>
          Try refreshing or navigating to another page. If you still see this
          message, our service might be disrupted.{' '}
          <a
            rel="noreferrer noopener"
            href="https://mail.google.com/mail/?view=cm&to=support@substrata.me"
            target="_blank"
          >
            Contact us
          </a>{' '}
          if the issues persists.
        </p>

        <Button
          className="not-found__text-btn"
          variant="common"
          onClick={redirectToHomePage}
        >
          Refresh
        </Button>
      </div>
      <img src={notFound} alt="Not Found" />
    </div>
  );
};
