import React from 'react';

type InfoTooltipProps = {
  data: string;
  className?: string;
};

export const InfoTooltip: React.FC<InfoTooltipProps> = ({
  data,
  className = '',
}: InfoTooltipProps): JSX.Element => {
  return <div className={`info-tooltip ${className}`}>{data}</div>;
};
