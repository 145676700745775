import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  RouteProps,
  useHistory,
} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { OrganizationsIcon } from './components/Icons/OrganizationsIcon';
import { GoogleCallback } from './pages/GoogleCallback/GoogleCallback';
import { Organizations } from './pages/Organizations/Organizations';
import { refreshToken } from './store/auth/auth.actions';
import { RootState } from './store/store';
import { RouteI, User } from './types';
import { SIGNUP_LINK, SUPER_ACCOUNT_URL } from './constants/config';

export const ROUTES: RouteI[] = [
  {
    path: '/orgs',
    Component: Organizations,
    label: 'Organizations',
    tooltip: 'Organizations',
    Icon: OrganizationsIcon,
    roles: ['superAdmin'],
  },
];

interface ProtectedRouteProps extends RouteProps {
  roles: string[];
  user: User | null;
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  roles,
  user,
  children,
  ...props
}: ProtectedRouteProps): JSX.Element | null => {
  return roles.includes(user?.role as string) && user?.isEmailVerified ? (
    <Route {...props}>
      {({ match }) => (
        <CSSTransition timeout={500} classNames="routes" in={match != null}>
          {children}
        </CSSTransition>
      )}
    </Route>
  ) : null;
};

export const AppRouting: React.FC = (): JSX.Element | null => {
  const { user, authError, isSignOut } = useSelector(
    (state: RootState) => state.auth
  );
  const history = useHistory();
  const dispatch = useDispatch();

  const currentPath = history.location.pathname;

  useEffect(() => {
    if (!user && localStorage.getItem('token')) {
      if (authError.refresh) {
        window.location.href = `${SIGNUP_LINK}/login?client=superAdmin&returnUrl=${SUPER_ACCOUNT_URL}`;
      }
      dispatch(refreshToken());
    } // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSignOut && !user) window.location.href = `${SIGNUP_LINK}/login`;
    if (user && user.role !== 'superAdmin') history.push('/no-permission');
  }, [isSignOut, user, currentPath]); // eslint-disable-line

  return (
    <TransitionGroup>
      <Switch>
        {ROUTES.map((route) => {
          const { path, Component, roles } = route;
          return (
            <ProtectedRoute
              roles={roles}
              key={path}
              exact
              path={path}
              user={user}
            >
              <Component />
            </ProtectedRoute>
          );
        })}
        <Route path="/google-callback">
          <GoogleCallback />
        </Route>
        <Route path="*">
          <Redirect to="/not-found" />
        </Route>
      </Switch>
    </TransitionGroup>
  );
};
