import React, { useEffect, useRef } from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import { useOrgTable } from './organizations.utils';
import { AddOrganization } from './components/AddOrganization/AddOrganization';
import { useToggle } from '../../components';
import { RootState } from '../../store/store';
import { DeleteOrgModal } from './components/DeleteOrgModal/DeleteOrgModal';
import { AddIcon } from '../../components/Icons/AddIcon';
import { Loader } from './components/Loader/Loader';

export const Organizations: React.FC = (): JSX.Element => {
  const {
    rowSelection,
    cols,
    orgs,
    handleDelete,
    editOpen,
    handleEdit,
  } = useOrgTable();

  const { open, toggle } = useToggle(false);

  const { deleteModalOpen, currentOrg } = useSelector(
    (state: RootState) => state.orgs
  );

  const orgsCountRef = useRef(orgs.length);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (orgs.length > 1 && orgs.length - orgsCountRef.current === 1) {
      const elementToScroll = document.getElementsByClassName(
        'ant-table-body'
      )[0];
      if (elementToScroll) {
        const { scrollHeight } = elementToScroll;
        elementToScroll.scrollTo({ top: scrollHeight, behavior: 'smooth' });
      }
    }
    orgsCountRef.current = orgs.length;
    return () => clearTimeout(timeout);
  }, [orgs]);

  return (
    <div>
      <div className="table__controls">
        <div className="table__controls-left">
          <AddIcon tooltipText="Add new organization" onClick={toggle} />
        </div>
      </div>
      <Table
        rowSelection={{ type: 'checkbox', ...rowSelection }}
        columns={cols}
        dataSource={orgs}
        rowClassName={(record) => (record.invitationSent ? 'disabled' : '')}
        pagination={false}
        scroll={{ y: 580 }}
        loading={{ indicator: <Loader />, spinning: !orgs.length }}
        tableLayout="auto"
      />
      <AddOrganization isAdd open={open} onClose={toggle} />
      <AddOrganization org={currentOrg} open={editOpen} onClose={handleEdit} />
      <DeleteOrgModal open={deleteModalOpen} onClose={handleDelete} />
    </div>
  );
};
