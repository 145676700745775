import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { refreshToken, signIn } from '../../store/auth/auth.actions';
import { RootState } from '../../store/store';

import { SIGNUP_LINK, SUPER_ACCOUNT_URL } from '../../constants/config';

export const Login: React.FC = (): null => {
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, org, authError } = useSelector(
    (state: RootState) => state.auth
  );

  const currentPath = history.location.pathname;
  const params = new URLSearchParams(search);
  const authCode = params.get('authCode');

  const conditionRedirectToSignUp = !authCode && !localStorage.getItem('token');

  const conditionToRefreshToken =
    (!user || !org) && (authCode || localStorage.getItem('token'));

  useEffect(() => {
    if (conditionRedirectToSignUp)
      window.location.href = `${SIGNUP_LINK}/login?client=superAdmin&returnUrl=${SUPER_ACCOUNT_URL}`;
    if (conditionToRefreshToken) {
      if (authCode) dispatch(signIn(authCode));
      else {
        if (authError.refresh) window.location.href = `${SIGNUP_LINK}/login`;
        dispatch(refreshToken());
      }
    }
    if (user && user.role !== 'superAdmin') {
      history.push('/no-permission');
    }
    // eslint-disable-next-line
  }, [user, org, authCode, authError, currentPath, dispatch]);

  if (user && org && currentPath !== '/orgs') history.push('/orgs');

  return null;
};
