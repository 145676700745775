import React, { useState } from 'react';
import { InviteStatus } from '../../../types';
import { InfoTooltip } from './InfoTooltip';

type InvitationStatusProps = {
  status: InviteStatus;
};

export const InvitationStatus: React.FC<InvitationStatusProps> = ({
  status,
}: InvitationStatusProps): JSX.Element => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleShowTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div
      onMouseEnter={handleShowTooltip}
      onMouseLeave={handleShowTooltip}
      className="invitation-status"
    >
      Invitation {status}
      {showTooltip && status === 'expired' ? (
        <InfoTooltip
          className="invitation-status__tooltip"
          data="The invitation has expired, you can resend it through the dotted menu"
        />
      ) : null}
    </div>
  );
};
