import { FormikErrors, FormikTouched, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
  addOrganization,
  updateOrganization,
} from '../../../../store/orgs/orgs.actions';
import { RootState } from '../../../../store/store';
import { Organization } from '../../../../types';

export type AddOrgForm = {
  email?: string;
  orgName?: string;
  url?: string;
  orgSeats?: number | string;
  acquisitionSource?: string | null;
  stripeCustomerId?: string | null;
  analyze?: boolean;
  simulate?: boolean;
  inlineMenu?: boolean;
  deals?: boolean;
  insights?: boolean;
  analytics?: boolean;
  teams?: boolean;
  teamAnalytics?: boolean;
  eventNotifications?: boolean;
  xRayAnalysis?: boolean;
  xRaySimulation?: boolean;
  webhooks?: boolean;
  soundwave?: boolean;
};

type UseOrgFormParams = {
  onClose: () => void;
  isAdd: boolean;
  org?: Organization | null;
  isMyOrgEdit?: boolean;
};

type UseOrgFormReturn = {
  handleCancel: () => void;
  errors: FormikErrors<AddOrgForm>;
  handleChange: (e: string | React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  setFieldValue: (
    field: string,
    value: boolean,
    shouldValidate?: boolean
  ) => void;
  touched: FormikTouched<AddOrgForm>;
  values: AddOrgForm;
  isErrorMsg: boolean;
  isOrgCreated: boolean;
  isLoading: boolean;
};

export const useOrgForm = ({
  isAdd,
  onClose,
  org,
}: UseOrgFormParams): UseOrgFormReturn => {
  const { user, org: organization } = useSelector(
    (state: RootState) => state.auth
  );

  const { isErrorMsg, isOrgCreated, currentOrg, status } = useSelector(
    (state: RootState) => state.orgs
  );

  const { editLoading } = status;

  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    orgName: Yup.string().required('This field is required'),
    url: Yup.string().url().required('This field is required'),
    email: Yup.string().email().trim().required('This field is required'),
    location: Yup.string().nullable(),
    orgSeats: Yup.number().typeError('Must be only digits'),
    soundwave: Yup.string(),
  });

  const handleSubmit = (values: AddOrgForm) => {
    const checkBooleansDiff =
      (currentOrg?.enabledFeatures &&
        JSON.stringify(Object.values(values).slice(5)) !==
          JSON.stringify(Object.values(currentOrg.enabledFeatures))) ||
      false;

    const valuesDiffCheck =
      (values.url?.length && values.url !== currentOrg?.url) ||
      (values.orgSeats?.toString().length &&
        +values.orgSeats !== currentOrg?.maxUsersCount) ||
      values.acquisitionSource !== currentOrg?.acquisitionSource ||
      checkBooleansDiff;

    if (isAdd) {
      const valuesToCreateOrg = {
        orgName: values.orgName,
        email: values.email?.trim(),
        url: values.url,
        maxUsersCount: values?.orgSeats?.toString().replace('+', '') || '5',
        acquisitionSource: values.acquisitionSource,
        features: [
          {
            feature: 'emailAnalyzer',
            enabled: values.analyze || false,
          },
          {
            feature: 'emailSimulator',
            enabled: values.simulate || false,
          },
          {
            feature: 'inlineMenu',
            enabled: values.inlineMenu || false,
          },
          {
            feature: 'myDeals',
            enabled: values.deals || false,
          },
          {
            feature: 'myInsights',
            enabled: values.insights || false,
          },
          {
            feature: 'myAnalytics',
            enabled: values.analytics || false,
          },
          {
            feature: 'teams',
            enabled: values.teams || false,
          },
          {
            feature: 'teamAnalytics',
            enabled: values.teamAnalytics || false,
          },
          {
            feature: 'eventNotifications',
            enabled: values.eventNotifications || false,
          },
          {
            feature: 'xRayAnalysis',
            enabled: values.xRayAnalysis || false,
          },
          {
            feature: 'xRaySimulation',
            enabled: values.xRaySimulation || false,
          },
          {
            feature: 'webhooks',
            enabled: values.webhooks || false,
          },
          {
            feature: 'soundwave',
            enabled: values.soundwave || false,
          },
        ],
      };
      dispatch(
        addOrganization(
          valuesToCreateOrg,
          user?.orgId || '',
          user?.userId || ''
        )
      );
    } else if (valuesDiffCheck) {
      dispatch(
        updateOrganization(
          {
            url: values.url,
            maxUsersCount: values?.orgSeats?.toString().replace('+', ''),
            acquisitionSource: values.acquisitionSource,
            features: [
              {
                feature: 'emailAnalyzer',
                enabled: values.analyze || false,
              },
              {
                feature: 'emailSimulator',
                enabled: values.simulate || false,
              },
              {
                feature: 'inlineMenu',
                enabled: values.inlineMenu || false,
              },
              {
                feature: 'myDeals',
                enabled: values.deals || false,
              },
              {
                feature: 'myInsights',
                enabled: values.insights || false,
              },
              {
                feature: 'myAnalytics',
                enabled: values.analytics || false,
              },
              {
                feature: 'teams',
                enabled: values.teams || false,
              },
              {
                feature: 'teamAnalytics',
                enabled: values.teamAnalytics || false,
              },
              {
                feature: 'eventNotifications',
                enabled: values.eventNotifications || false,
              },
              {
                feature: 'xRayAnalysis',
                enabled: values.xRayAnalysis || false,
              },
              {
                feature: 'xRaySimulation',
                enabled: values.xRaySimulation || false,
              },
              {
                feature: 'webhooks',
                enabled: values.webhooks || false,
              },
              {
                feature: 'soundwave',
                enabled: values.soundwave || false,
              },
            ],
          },
          org?.orgId || '',
          org?.orgId === organization?.orgId
        )
      );
      onClose();
    }
  };

  const formik = useFormik<AddOrgForm>({
    initialValues: {
      orgName: isAdd ? '' : org?.name || '',
      url: isAdd ? '' : org?.url || '',
      email: isAdd ? '' : org?.primaryEmail || '',
      orgSeats: isAdd ? '' : org?.maxUsersCount.toString() || '',
      acquisitionSource: isAdd ? '' : org?.acquisitionSource || '',
      stripeCustomerId: isAdd ? '' : org?.stripeCustomerId || '',
      analyze: isAdd ? false : org?.enabledFeatures?.emailAnalyzer || false,
      simulate: isAdd ? false : org?.enabledFeatures?.simulator || false,
      inlineMenu: isAdd ? false : org?.enabledFeatures?.inlineMenu || false,
      deals: isAdd ? false : org?.enabledFeatures?.myDeals || false,
      insights: isAdd ? false : org?.enabledFeatures?.myInsights || false,
      analytics: isAdd ? false : org?.enabledFeatures?.myAnalytics || false,
      teams: isAdd ? false : org?.enabledFeatures?.teams || false,
      teamAnalytics: isAdd
        ? false
        : org?.enabledFeatures?.teamAnalytics || false,
      eventNotifications: isAdd
        ? false
        : org?.enabledFeatures?.eventNotifications || false,
      xRayAnalysis: isAdd ? false : org?.enabledFeatures?.xRayAnalysis || false,
      xRaySimulation: isAdd
        ? false
        : org?.enabledFeatures?.xRaySimulation || false,
      webhooks: isAdd ? false : org?.enabledFeatures?.webhooks || false,
      soundwave: isAdd ? false : org?.enabledFeatures?.soundwave || false,
    },
    onSubmit: handleSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const handleCancel = () => {
    formik.resetForm();
    onClose();
  };

  return {
    ...formik,
    handleCancel,
    isErrorMsg,
    isOrgCreated,
    isLoading: editLoading,
  };
};
