import { Organization, User } from '../../types';
import { EDIT_ORG } from '../orgs/orgs.actions';
import {
  CALL_GOOGLE_CALLBACK,
  CALL_GOOGLE_CALLBACK_FAILURE,
  CLEAR_RESEND_EMAIL_MESSAGE,
  GOOGLE_SIGN_IN,
  REFRESH_TOKEN,
  RESEND_EMAIL,
  SEND_FORGOT_PASSWORD_EMAIL,
  SEND_FORGOT_PASSWORD_EMAIL_FAILURE,
  SIGN_IN,
  SIGN_OUT,
  TOGGLE_APP,
  VERIFY_RESET_PASSWORD_CODE,
  VERIFY_RESET_PASSWORD_CODE_FAILURE,
  REFRESH_TOKEN_ERROR,
} from './auth.actions';
import { AuthActions } from './auth.types';

export type AuthState = {
  user: User | null;
  org: Organization | null;
  googleUrl: string;
  resendEmailSuccess: string;
  forgotPassword: {
    error: boolean;
    success: boolean;
  };
  authError: {
    common: boolean;
    google: boolean;
    refresh: boolean;
  };
  isSignedInUser: boolean;
  isSignOut: boolean;
};

const initialState: AuthState = {
  user: null,
  org: null,
  googleUrl: '',
  resendEmailSuccess: '',
  forgotPassword: {
    error: false,
    success: false,
  },
  authError: {
    common: false,
    google: false,
    refresh: false,
  },
  isSignedInUser: false,
  isSignOut: false,
};

export const authReducer = (
  state: AuthState = initialState,
  action: AuthActions
): AuthState => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        ...action.payload,
        authError: {
          common: false,
          google: false,
          refresh: false,
        },
      };
    case REFRESH_TOKEN:
      return {
        ...state,
        user: action.payload.user,
        org: action.payload.org,
      };
    case CALL_GOOGLE_CALLBACK:
    case REFRESH_TOKEN_ERROR:
      return {
        ...state,
        authError: {
          ...state.authError,
          refresh: true,
        },
        isSignedInUser: true,
      };
    case EDIT_ORG:
      return {
        ...state,
        org: action.payload.isCurrentOrg ? action.payload.org : state.org,
      };
    case GOOGLE_SIGN_IN:
      return {
        ...state,
        googleUrl: action.payload,
      };
    case RESEND_EMAIL:
      return {
        ...state,
        resendEmailSuccess: action.payload,
      };
    case CLEAR_RESEND_EMAIL_MESSAGE:
      return {
        ...state,
        resendEmailSuccess: '',
      };
    case SIGN_OUT:
      return {
        ...initialState,
        isSignOut: true,
      };
    case VERIFY_RESET_PASSWORD_CODE_FAILURE:
    case SEND_FORGOT_PASSWORD_EMAIL_FAILURE:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          error: true,
          success: false,
        },
      };
    case VERIFY_RESET_PASSWORD_CODE:
    case SEND_FORGOT_PASSWORD_EMAIL:
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          success: true,
          error: false,
        },
      };
    case TOGGLE_APP:
      return {
        ...state,
        user: state.user && {
          ...state.user,
          apps:
            state.user?.apps.map((app) =>
              app.name === action.payload.appName
                ? { ...app, active: action.payload.active }
                : app
            ) || [],
        },
      };
    case CALL_GOOGLE_CALLBACK_FAILURE:
      return {
        ...state,
        authError: {
          ...state.authError,
          google: true,
        },
      };
    default:
      return state;
  }
};
